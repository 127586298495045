import { get, post } from 'config/api/config';

const GET_SPECIAL_DETAILS_ENDPOINT = '/viewer/specials/special-details';
const ACCEPT_OFFERS_ENDPOINT = '/viewer/specials/accept-offers';
const VALIDATE_SPECIALS_ENDPOINT = '/viewer/specials/validate-specials';

/**
 * GET specials
 * @returns promise
 */
export const getSpecials = async () => {
  const response = await get(`${GET_SPECIAL_DETAILS_ENDPOINT}`);
  return response.data;
};

/**
 * Accept offers
 * @returns promise
 */
export const acceptOffers = async (leaseTermId: number, userId: number, quoteId?: number): Promise<any> => {
  const url = `${ACCEPT_OFFERS_ENDPOINT}?leaseTermId=${leaseTermId}&userId=${userId}&quoteId=${quoteId ?? ''}`;
  const response = await post(url);
  return response;
};

/**
 * Validate specials
 * @returns promise
 */
export const validateSpecials = async (
  isSkipCouponCodeValidation?: boolean, 
  couponCode?: string, 
  spaceConfigurationId?: number
): Promise<any> => {
  const url = `${VALIDATE_SPECIALS_ENDPOINT}?isSkipCouponCodeValidation=${isSkipCouponCodeValidation ?? ''}&couponCode=${couponCode ?? ''}&spaceConfigurationId=${spaceConfigurationId ?? ''}`;
  const response = await post(url);
  return response;
};
