"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApplicationRoute = void 0;
var ApplicationRoute = /** @class */ (function () {
    function ApplicationRoute() {
        this.name = '';
        this.description = '';
        this.clientId = 0;
    }
    return ApplicationRoute;
}());
exports.ApplicationRoute = ApplicationRoute;
