"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.CITIZEN_STATUS_OPTIONS = exports.SSN_EXCEPTION_OPTIONS = exports.OWNERSHIP_STATUS = exports.DISABILITY_TYPE_OPTIONS = exports.HIGHER_LEARNING_STATUS_OPTIONS = exports.YES_NO_OPTIONS = exports.PET_GENDER = exports.PAYMENT_INTERVAL = void 0;
var entities_1 = require("@common/entities");
exports.PAYMENT_INTERVAL = (_a = {},
    _a[entities_1.Interval.Monthly] = 'Monthly',
    _a[entities_1.Interval.Weekly] = 'Weekly',
    _a[entities_1.Interval.Yearly] = 'Yearly',
    _a);
exports.PET_GENDER = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
];
exports.YES_NO_OPTIONS = [
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' },
];
exports.HIGHER_LEARNING_STATUS_OPTIONS = [
    {
        label: 'Full Time',
        value: 'full_time',
    },
    {
        label: 'Part Time',
        value: 'part_time',
    },
];
exports.DISABILITY_TYPE_OPTIONS = [
    {
        value: 'disability_mobility',
        label: 'Mobility',
    },
    {
        value: 'disability_hearing',
        label: 'Hearing',
    },
    {
        value: 'disability_visual',
        label: 'Visual',
    },
    {
        value: 'disability_mental',
        label: 'Mental',
    },
];
exports.OWNERSHIP_STATUS = [
    { label: 'Owner', value: 'owner' },
    {
        label: 'Renter',
        value: 'renter',
    },
];
exports.SSN_EXCEPTION_OPTIONS = [
    {
        label: 'Individual who does not contend eligible immigration status.',
        value: 'ssn_exception_description_1',
    },
    {
        label: 'Individuals age 62 or older as of January 31, 2010, whose initial determination of eligibility in either a Multifamily or Public and Indian Housing program was begun prior to January 31, 2010 (a break in assistance does not void the exemption).',
        value: 'ssn_exception_description_2',
    },
    {
        label: 'New household member under the age of 6 where disclosure of SSN is delayed for 90 - 180 days.',
        value: 'ssn_exception_description_3',
    },
    {
        label: 'Foster child or adult whose SSN has not been disclosed by the foster agency and for whom HUD has issues a waiver.',
        value: 'ssn_exception_description_4',
    },
];
exports.CITIZEN_STATUS_OPTIONS = [
    {
        id: 1,
        value: 'citizen_or_national',
        name: 'Individual is a citizen or national',
    },
    {
        id: 2,
        value: 'eligible_immigration_status',
        name: 'Individual is a noncitizen with eligible immigration status',
    },
    {
        id: 3,
        value: 'non_ineligible_noncitizen',
        name: 'Non-Ineligible noncitizen child or a family head or spouse',
    },
    { id: 4, value: 'ineligible_noncitizen', name: 'Ineligible Non-Citizen' },
    {
        id: 5,
        value: 'ineligible_parent',
        name: 'Ineligible parent of a Head or Spouse',
    },
    { id: 6, value: 'no_documentation', name: 'No documentation submitted' },
    { id: 7, value: 'pending_verification', name: 'Pending verification' },
    { id: 8, value: 'not_family_member', name: 'Not a family member' },
];
