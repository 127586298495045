"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.YES_NO_OPTIONS = void 0;
exports.YES_NO_OPTIONS = [
    {
        name: 'yes',
        sequence: 1,
        label: 'Yes',
    },
    {
        name: 'no',
        sequence: 2,
        label: 'No',
    },
];
