"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntrataApplication = void 0;
var enums_1 = require("./enums");
var EntrataApplication = /** @class */ (function () {
    function EntrataApplication() {
        this.relationshipType = enums_1.RelationshipType.Primary;
        this.orgId = 0;
        this.propertyName = '';
        this.applicantId = '';
        this.propertyName = '';
        this.propertyId = 0;
        this.applicationId = 0;
        this.moveInDate = '';
        this.applicationStatus = enums_1.EntrataApplicationStatus.Started;
        this.applicationStage = enums_1.EntrataApplicationStage.Application;
        this.isWaitlistEnabled = false;
    }
    return EntrataApplication;
}());
exports.EntrataApplication = EntrataApplication;
