"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Unit = void 0;
var Unit = /** @class */ (function () {
    function Unit() {
        this.id = 0;
        this.unitSpaceId = 0;
        this.number = '';
        this.buildingId = 0;
        this.buildingName = '';
        this.floorId = 0;
        this.floorName = '';
        this.squareFeet = 0;
        this.availableOn = '';
        this.minRent = '';
        this.maxRent = '';
        this.minDeposit = '';
        this.maxDeposit = '';
        this.leaseTerms = [];
        this.amenities = [];
        this.allowOnWebsite = false;
    }
    return Unit;
}());
exports.Unit = Unit;
