import { PageType, SectionElementType } from '@common/entities';

import { Block, Field } from 'types/fields';
import { Section } from 'types/page';

/**
 * Return error message
 * @param {string} type
 * @param {Number} stepErrorLength
 * @returns string
 */
export function prepareError(
  type: string,
  stepErrorLength: number,
): string | null {
  switch (type) {
    case PageType.Blank:
    case PageType.Options:
      return `${stepErrorLength} Incomplete Fields.`;
    case PageType.FloorPlans:
      return 'Please select unit.';
    default:
      return null;
  }
}

export function getPageWiseFields(
  sections: Section[],
  type: PageType,
): { blockFields: string[]; singleFields: string[] } {
  const singleFields: string[] = [];
  const blockFields: string[] = [];

  if (!Array.isArray(sections) || !sections?.length)
    return { singleFields, blockFields };

  if (![PageType.Blank, PageType.Options].includes(type))
    return { singleFields, blockFields };

  sections.forEach(({ elements }) => {
    elements?.forEach((field: Block | Field) => {
      if (field?.elementType === SectionElementType.Block) {
        blockFields.push(field.customType);
        return;
      }
      singleFields.push(field?.name);
    });
  });

  return { singleFields, blockFields };
}
