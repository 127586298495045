import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { BlockType } from '@common/entities';
import { RootState } from 'state/store';

import { getPageWiseFields } from '../utils/steps';

import {
  useActiveStepIndexFromRedux,
  useGetActiveStepIndex,
} from './useGetActiveStepIndex';

export const useGetCurrentPageData = () => {
  const { getValues } = useFormContext();

  const { form } = useSelector((state: RootState) => state.application);
  const { pages } = form;

  const getActiveStepIndex = useGetActiveStepIndex();
  const activeStepIndexRedux = useActiveStepIndexFromRedux();

  const pageWiseFields = React.useMemo(() => {
    return pages.map(({ sections, type }: any) => {
      return getPageWiseFields(sections, type);
    });
  }, [pages]);

  /**
   * Get single fields form value with name
   */
  function getSingleFieldValue(fields: string[]) {
    if (!fields?.length) return [];

    const applicantData: { [key: string]: string } = {};

    fields.forEach((field) => {
      applicantData[field] = getValues(field) ?? '';
    });
    return [{ applicantData }];
  }

  /**
   * Get block field form values
   * @param fields
   * @returns
   */
  function getBlockFieldsValue(fields: string[]) {
    if (!fields?.length) return [];

    const returnData: { [key: string]: any }[] = [];

    fields.forEach((field) => {
      switch (field) {
        case BlockType.Income:
          returnData.push({
            [BlockType.Income]: getValues(BlockType.Income),
          });
          break;
        case BlockType.Asset:
          returnData.push({
            [BlockType.Asset]: getValues(BlockType.Asset),
          });
          break;
        case BlockType.Pet:
          returnData.push({ [BlockType.Pet]: getValues(BlockType.Pet) });
          break;
        case BlockType.Coapplicant:
          returnData.push({
            coApplicant: getValues('coApplicant'),
          });
          break;
        case BlockType.Vehicle:
          returnData.push({
            [BlockType.Vehicle]: getValues(BlockType.Vehicle),
          });
          break;
        case BlockType.IdentificationType:
          returnData.push({
            [BlockType.IdentificationType]: getValues(
              BlockType.IdentificationType,
            ),
          });
          break;
        case BlockType.Guarantor:
          returnData.push({
            guarantor: getValues('guarantor'),
          });
          break;
        case BlockType.AddressCurrent:
          returnData.push({
            [BlockType.AddressCurrent]: getValues(BlockType.AddressCurrent),
          });
          break;
        case BlockType.AddressPrevious:
          returnData.push({
            [BlockType.AddressPrevious]: getValues(BlockType.AddressPrevious),
          });
          break;
        case BlockType.GeneralQuestion:
          returnData.push({
            [BlockType.GeneralQuestion]: getValues(BlockType.GeneralQuestion),
          });
          break;
        case BlockType.Disability:
          returnData.push({
            [BlockType.Disability]: getValues(BlockType.Disability),
          });
          break;
        case BlockType.CitizenStatus:
          returnData.push({
            [BlockType.CitizenStatus]: getValues(BlockType.CitizenStatus),
          });
          break;
        case BlockType.Student:
          returnData.push({
            [BlockType.Student]: getValues(BlockType.Student),
          });
          break;
        case BlockType.HousedDeclaredDisaster:
          returnData.push({
            [BlockType.HousedDeclaredDisaster]: getValues(
              BlockType.HousedDeclaredDisaster,
            ),
          });
          break;
        case BlockType.HouseholdFleeingDomesticViolence:
          returnData.push({
            [BlockType.HouseholdFleeingDomesticViolence]: getValues(
              BlockType.HouseholdFleeingDomesticViolence,
            ),
          });
          break;
        case BlockType.MilitaryVeteran:
          returnData.push({
            [BlockType.MilitaryVeteran]: getValues(BlockType.MilitaryVeteran),
          });
          break;
        case BlockType.PoliceSecurityOfficer:
          returnData.push({
            [BlockType.PoliceSecurityOfficer]: getValues(
              BlockType.PoliceSecurityOfficer,
            ),
          });
          break;
        default:
          break;
      }
    });
    return returnData;
  }

  /**
   * Prepare the data for sending it to backend
   * @returns
   */
  const prepareData = () => {
    const activeStepIndex =
      activeStepIndexRedux >= 0 ? activeStepIndexRedux : getActiveStepIndex();

    const singleField = getSingleFieldValue(
      pageWiseFields[activeStepIndex].singleFields,
    );

    const blockField = getBlockFieldsValue(
      pageWiseFields[activeStepIndex].blockFields,
    );

    return [...singleField, ...blockField];
  };

  return prepareData;
};
