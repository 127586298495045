"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PET_FIELDS = void 0;
var entities_1 = require("@common/entities");
exports.PET_FIELDS = [
    {
        label: 'My pet is an assistance animal',
        type: entities_1.FieldType.Checkbox,
        sequence: 1,
        name: 'applicant_pet_assistance_animal',
        customType: entities_1.FieldType.Checkbox,
        required: true,
        enabled: true,
    },
    {
        label: 'Pet Type',
        type: entities_1.FieldType.Dropdown,
        sequence: 2,
        name: 'applicant_pet_type',
        customType: entities_1.FieldType.Dropdown,
        required: true,
        enabled: true,
    },
    {
        label: 'Pet Name',
        type: entities_1.FieldType.Text,
        sequence: 3,
        name: 'applicant_pet_name',
        customType: entities_1.FieldType.Text,
        required: true,
        enabled: true,
    },
    {
        label: 'Color',
        type: entities_1.FieldType.Text,
        sequence: 4,
        name: 'applicant_pet_color',
        customType: entities_1.FieldType.Text,
        required: true,
        enabled: true,
    },
    {
        label: 'Breed',
        type: entities_1.FieldType.Text,
        sequence: 5,
        name: 'applicant_pet_breed',
        customType: entities_1.FieldType.Text,
        required: true,
        enabled: true,
    },
    {
        label: 'Weight',
        type: entities_1.FieldType.Text,
        sequence: 6,
        name: 'applicant_pet_weight',
        customType: entities_1.FieldType.Text,
        required: true,
        enabled: true,
    },
    {
        label: 'Age',
        type: entities_1.FieldType.Text,
        sequence: 7,
        name: 'applicant_pet_age',
        customType: entities_1.FieldType.Text,
        required: true,
        enabled: true,
    },
    {
        label: 'Pet Gender',
        type: entities_1.FieldType.Picker,
        options: [
            { label: 'Male', value: 'Male' },
            { label: 'Female', value: 'Female' },
        ],
        customType: entities_1.FieldType.Picker,
        sequence: 8,
        name: 'applicant_pet_gender',
        required: true,
        enabled: true,
    },
    {
        label: 'Has your pet been spayed or neutered?',
        type: entities_1.FieldType.Radio,
        sequence: 9,
        customType: entities_1.FieldType.Radio,
        name: 'applicant_pet_spayed_or_neutered',
        required: true,
        enabled: false,
        options: [
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
        ],
    },
    {
        label: 'Date your pet was spayed or neutered',
        type: entities_1.FieldType.Date,
        sequence: 10,
        name: 'applicant_pet_date_spayed_or_neutered',
        placeholder: 'MM/DD/YYYY',
        customType: entities_1.FieldType.Date,
        required: true,
        enabled: false,
    },
    {
        label: 'Date of last rabies shot',
        type: entities_1.FieldType.Date,
        sequence: 11,
        name: 'applicant_pet_date_rabies_shot',
        placeholder: 'MM/DD/YYYY',
        customType: entities_1.FieldType.Date,
        required: true,
        enabled: false,
    },
    {
        label: 'License Number',
        type: entities_1.FieldType.Text,
        sequence: 12,
        name: 'applicant_pet_license_number',
        customType: entities_1.FieldType.Text,
        required: true,
        enabled: false,
    },
    {
        label: 'License City',
        type: entities_1.FieldType.Text,
        sequence: 13,
        name: 'applicant_pet_license_city',
        customType: entities_1.FieldType.Text,
        required: true,
        enabled: false,
    },
    {
        label: 'House Broken',
        type: entities_1.FieldType.Radio,
        sequence: 14,
        name: 'applicant_pet_house_broken',
        options: [
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
        ],
        customType: entities_1.FieldType.Radio,
        required: true,
        enabled: false,
    },
    {
        label: 'Owner Name',
        type: entities_1.FieldType.Text,
        sequence: 15,
        name: 'applicant_pet_owner_name',
        customType: entities_1.FieldType.Text,
        required: true,
        enabled: false,
    },
];
