import { FieldErrors, UseFormTrigger } from 'react-hook-form';
import { E_CHECK_ID } from '@common/ui';

import { PaymentScreening } from 'services';
import { ACHInfo, PaymentType } from 'types/payments';

import { validateOFAC } from './ofac-form-validation';

export function isValidACH(
  data: any,
  paymentSettings: { [key: string]: any },
  paymentMethodScreening?: PaymentScreening,
  errors?: FieldErrors<any>,
  trigger?: UseFormTrigger<any>,
) {
  if (data === null) {
    throw new Error('Account is not available. Please link your bank account.');
  }

  // OFAC check
  const { isScreeningEnabled = false } = paymentSettings;
  if (!isScreeningEnabled) return;

  if (!paymentMethodScreening) {
    throw new Error('Please provide account holder info.');
  }

  validateOFAC(paymentMethodScreening, errors ?? {}, trigger);
}

export function getECheckType(types: PaymentType[]) {
  return types.find((type) => type.id === E_CHECK_ID);
}

export function prepareACHRequest(
  data: ACHInfo,
  types: PaymentType[],
  paymentSettings: { [key: string]: any },
  paymentMethodScreening?: PaymentScreening,
) {
  const {
    accounts,
    institution,
    link_session_id: linkSessionId,
    public_token: publicToken,
  } = data;

  const { isScreeningEnabled = false } = paymentSettings;

  const type = getECheckType(types) as PaymentType;

  const returnData = {
    paymentTypeId: type?.id,
    achPlaidInfo: {
      linkSessionId,
      accountIds: accounts.map((i) => i.id),
      institutionId: institution.institution_id ?? null,
      institutionName: institution?.name ?? null,
      publicToken,
      verificationStatus: 'instant_auth',
    },
    isScreeningEnabled,
  };

  if (isScreeningEnabled && paymentMethodScreening) {
    const { is_self_verified: isSelfVerified } = paymentMethodScreening as any;
    return {
      ...returnData,
      paymentScreening: {
        ...paymentMethodScreening,
        is_self_verified: isSelfVerified ?? false,
      },
    };
  }
  return returnData;
}
