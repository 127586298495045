import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { NotificationStatus } from 'enums/notification-status';
import { resetSaveTrigger, setSaveCompleted } from 'state/slices/actionsSlice';
import { updateLeasingJourneyErrors } from 'state/slices/navigationSlice';
import { RootState } from 'state/store';

import notification from 'components/notification';
import { patchResponse, patchResponseForId } from 'services';
import { AddonOption } from 'types/addon';
import { extractErrorMessage } from 'utils/extractErrorMessage';
import { getErrorCount } from 'utils/getErrors';
import handleError from 'utils/handleError';

interface Props {
  activeStepIndex: number;
  responseId?: string;
}

export function useSaveOptionsPageData(props: Props) {
  const { activeStepIndex, responseId } = props;
  const {
    getValues,
    formState: { errors },
  } = useFormContext();

  const dispatch = useDispatch();
  const { form, activeResponse } = useSelector(
    (state: RootState) => state.application,
  );
  const { saveTriggerCounter = 0 } = useSelector(
    (state: RootState) => state.actions || {},
  );
  const { pages } = form;
  const { applicationId } = activeResponse;

  const { mutateAsync: updateResponse } = useMutation({
    mutationFn: patchResponse,
  });

  const { mutateAsync: updateResponseForId } = useMutation({
    mutationFn: patchResponseForId,
    onSuccess: () => {
      dispatch(setSaveCompleted(true));
      if (saveTriggerCounter > 0) {
        dispatch(resetSaveTrigger());
        notification('Save successful', NotificationStatus.SUCCESS);
      }
    },
    onError: (error) => {
      dispatch(setSaveCompleted(true));
      const errorMessage = extractErrorMessage(error);
      handleError(`Form Viewer - ${errorMessage}: ${error}`, `${errorMessage}`);
    },
  });

  const { propertyId } = useParams();

  function isPropertyIdAvailable() {
    let isAvailable = true;
    if (!propertyId) {
      isAvailable = false;
      handleError(
        `Form Viewer - Property ID not available`,
        `Property ID not available`,
      );
    }
    return isAvailable;
  }

  async function saveOptionPageData(
    newPageIndex: number,
    addOns: AddonOption[],
  ) {
    if (!isPropertyIdAvailable()) return;
    const errorCount = getErrorCount(errors);
    const responses = [];

    const petData = getValues('pet');
    if (petData !== undefined) {
      responses.push({ pet: petData });
    }

    if (addOns.length) {
      responses.push({ addOns });
    }

    if (!responses.length) return;
    try {
      const requestData: any = {
        currentPageId: pages[activeStepIndex].id,
        targetPageId: pages[newPageIndex].id,
        applicationId,
        propertyId,
        responses,
        errorCount,
      };

      if (responseId) {
        await updateResponseForId({ responseId, responseData: requestData });
      } else {
        await updateResponse(requestData);
      }

      dispatch(
        updateLeasingJourneyErrors({
          pageIndex: activeStepIndex,
          count: errorCount,
        }),
      );
    } catch (error) {
      handleError(
        `Form Viewer - Error saving options: ${error}`,
        `Error saving options`,
      );
    }
  }

  return saveOptionPageData;
}
