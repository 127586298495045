"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// check if color is light or dark so we can change the text color accordingly
var isLightColor = function (color) {
    var rgb = parseInt(color.slice(1), 16);
    var r = (rgb >> 16) & 0xff;
    var g = (rgb >> 8) & 0xff;
    var b = (rgb >> 0) & 0xff;
    var brightness = (r * 299 + g * 587 + b * 114) / 1000;
    // Adjust the threshold of what is considered a light or dark color
    return brightness > 150;
};
var Button = {
    // The styles all button have in common
    baseStyle: {
        fontWeight: 'medium',
        borderRadius: 'lg',
    },
    sizes: {
        sm: {
            fontSize: '13px',
            lineHeight: 4,
            py: 2,
            px: 4,
        },
        md: {
            fontSize: 'md',
            py: 2,
            px: 4,
        },
        lg: {
            fontSize: 'md',
            py: 5,
            px: 6,
        },
    },
    variants: {
        brand: function (props) {
            var _a = props.theme.colors.brand, accent1 = _a.accent1, accent2 = _a.accent2;
            var textColor = isLightColor(accent1) ? '#000' : '#fff';
            var hoverTextColor = isLightColor(accent2) ? '#000' : '#fff';
            return {
                bg: "brand.accent1",
                color: "".concat(textColor),
                _hover: {
                    bg: 'brand.accent2 !important',
                    color: "".concat(hoverTextColor, " !important"),
                },
            };
        },
        outline: {
            border: '2px solid',
            borderColor: 'black.900',
            color: 'black.900',
            bg: '#fff',
            _hover: {
                borderColor: 'gray.400',
                color: 'gray.400',
                bg: '#fff',
            },
        },
        primary: {
            bg: 'blackAlpha.900',
            color: '#fff',
            _hover: { bg: 'gray.700 !important' },
        },
        secondary: {
            bg: 'blue.highlight',
            color: 'blue.darkest',
            _hover: { bg: 'blue.light' },
        },
        success: {
            bg: 'green.500',
            color: '#fff',
            _hover: { bg: 'green.base' },
        },
        action: {
            bg: 'blue.500',
            color: '#fff',
            _hover: { bg: 'blue.base' },
        },
        link: {
            color: 'blackAlpha.900',
            _hover: { color: 'gray.700' },
        },
        neutral: {
            bg: 'gray.highlight',
            color: 'black.darkest',
            _hover: { bg: 'gray.medium' },
        },
        caution: {
            bg: 'yellow.highlight',
            color: 'yellow.darkest',
            _hover: { bg: 'yellow.light' },
        },
        danger: {
            bg: 'red.600',
            color: '#fff',
            _hover: { bg: 'red.400' },
        },
    },
    // The default size and variant values
    defaultProps: {
        size: 'md',
        variant: 'secondary',
    },
};
exports.default = Button;
