import { UseFormTrigger } from 'react-hook-form';

import { PaymentScreening } from 'services';

export function validateOFAC(
  data: PaymentScreening,
  errors: { [key: string]: any },
  trigger?: UseFormTrigger<any>,
) {
  if (Object.keys(errors).length) {
    throw new Error('Fill all the mandatory fields.');
  }

  const {
    applicant_name_middle: middleName,
    dont_have_middle_name: dontHaveMiddleName,
    street_line2: streetLine2,
    is_self_verified: isSelfVerified,
    ...rest
  } = data ?? {};

  let isValid = true;
  if (!dontHaveMiddleName && !middleName) {
    isValid = false;
  }
  if (isValid && Object.keys(rest).length) {
    Object.values(rest).forEach((value) => {
      if (!value) {
        isValid = false;
      }
    });
  }

  if (!isValid) {
    trigger && trigger();
    throw new Error('Fill all the mandatory fields.');
  }
}
