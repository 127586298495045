import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { PageType } from '@common/entities';
import { CREDIT_DEBIT_CARD, E_CHECK, MONEY_GRAM } from '@common/ui';
import { RootState } from 'state/store';

import { useCheckIsUnitReserved } from 'hooks/floor-plans';
import { isValidACH } from 'utils/payment/ach-utils';
import { isValidCard } from 'utils/payment/card-utils';

import { useProcessACHPayment } from './useProcessACHPayment';
import { useProcessCardPayment } from './useProcessCardPayment';
import { useProcessMoneyGramPayment } from './useProcessMoneyGramPayment';

export function useProcessPayment() {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('');

  const {
    getValues,
    trigger,
    formState: { errors },
  } = useFormContext();

  const { form, applicant } = useSelector(
    (state: RootState) => state.application,
  );
  const { pages } = form;
  const { unit } = useSelector((state: RootState) => state.unitSelection);
  const {
    error: missingPaymentField,
    paymentInfo,
    selectedPaymentType,
    termsAndConditionAck,
    paymentSettings,
  } = useSelector((state: RootState) => state.payment);

  const checkIsUnitReserved = useCheckIsUnitReserved();
  const { processCardPayment } = useProcessCardPayment();
  const { processACHPayment } = useProcessACHPayment();
  const { processMoneyGramPayment } = useProcessMoneyGramPayment();

  async function isUnitStillAvailable() {
    if (!unit) throw new Error('Unit is not selected, Please select the unit.');

    setLoadingText('Checking unit availability...');
    const isReserved = await checkIsUnitReserved(
      unit?.unitSpaceId,
      applicant.propertyId,
    );
    if (!isReserved) {
      throw new Error(
        'Selected Unit is not available, Please select another unit.',
      );
    }
  }

  function isFloorPlanPageAvailable() {
    return pages.findIndex((page) => page.type === PageType.FloorPlans);
  }

  function checkUsersInput() {
    switch (selectedPaymentType) {
      case CREDIT_DEBIT_CARD:
        return isValidCard(
          paymentInfo,
          missingPaymentField,
          paymentSettings,
          getValues('paymentMethodScreening'),
          errors,
          trigger,
        );
      case E_CHECK:
        return isValidACH(
          paymentInfo,
          paymentSettings,
          getValues('paymentMethodScreening'),
          errors,
          trigger,
        );
      case MONEY_GRAM:
        return true;
      default:
        throw new Error('Invalid payment option.');
    }
  }

  async function checksBeforeProceedingToPayments() {
    checkUsersInput();

    if (!termsAndConditionAck) {
      throw new Error('Please agree to the terms and conditions');
    }

    if (isFloorPlanPageAvailable() >= 0) {
      await isUnitStillAvailable();
    }
  }

  async function processedToPayment() {
    setLoadingText('Processing Payment...');
    switch (selectedPaymentType) {
      case CREDIT_DEBIT_CARD:
        await processCardPayment(getValues('paymentMethodScreening'));
        break;
      case E_CHECK:
        await processACHPayment(getValues('paymentMethodScreening'));
        break;
      case MONEY_GRAM:
        await processMoneyGramPayment();
        break;
      default:
        throw new Error('Payment method is not available.');
    }
  }

  async function processPayment() {
    setIsLoading(true);
    try {
      await checksBeforeProceedingToPayments();
      await processedToPayment();
    } catch (err: any) {
      throw new Error(err.message);
    } finally {
      setIsLoading(false);
      setLoadingText('');
    }
  }

  return {
    isLoading,
    loadingText,
    processPayment,
  };
}
