import { useMutation } from '@tanstack/react-query';

import { patchResponse } from 'services';

interface SaveResponseProps {
  currentPageId: string;
  applicationId: string;
  propertyId: number;
  responses: { [key: string]: any }[];
  errorCount?: number;
  targetPageId?: string;
}

export function useSaveResponse() {
  const { mutateAsync: updateResponse } = useMutation({
    mutationFn: patchResponse,
  });

  async function saveResponse(data: SaveResponseProps) {
    const response = await updateResponse(data);
    return response;
  }

  return saveResponse;
}
