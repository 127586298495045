"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.PAYMENT_INTERVAL = void 0;
var entities_1 = require("@common/entities");
exports.PAYMENT_INTERVAL = (_a = {},
    _a[entities_1.Interval.Monthly] = 'Monthly',
    _a[entities_1.Interval.Weekly] = 'Weekly',
    _a[entities_1.Interval.Yearly] = 'Yearly',
    _a);
