interface Param {
  [key: string]: any;
}
export function convertStringBooleans<T extends Param>(obj: T): T {
  const newObj: any = {};

  Object.keys(obj).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];

      if (typeof value === 'string') {
        const lowerCaseValue = value.toLowerCase();
        if (lowerCaseValue === 'true') {
          newObj[key] = true;
        } else if (lowerCaseValue === 'false') {
          newObj[key] = false;
        } else {
          newObj[key] = value; // Leave as string if not "true" or "false"
        }
      } else {
        newObj[key] = value; // Leave other data types as they are
      }
    }
  });

  return newObj;
}
