import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EntrataApplicationStatus } from '@common/entities';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from 'AppWrapper';
import { EntrataSession } from 'auth/EntrataSession';
import { setApplicationData } from 'state/slices/applicationSlice';
import { setIsAuthenticated, setIsPreviewMode } from 'state/slices/authSlice';
import { updateResponseState } from 'state/slices/responseSlice';

import { useInitializeLJApplication } from 'hooks/useInitializeLJApplication';
import { getResponse, postUserLogin } from 'services';
import {
  ApplicationStatusResponse,
  getApplicantApplications,
} from 'services/applicant';
import { Applicant } from 'types/applicant';
import { Form } from 'types/form';
import { prepareFormInitializingData } from 'utils/form';

interface LoginRequest {
  orgId: number;
  propertyId: number;
  username: string;
  password: string;
}
const useLoginUser = () => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const initializeLJApplication = useInitializeLJApplication();
  const { mutateAsync } = useMutation({ mutationFn: postUserLogin });
  const navigate = useNavigate();

  const getApplicationsApplication = async () => {
    try {
      const { data } = await queryClient.fetchQuery({
        queryKey: ['applicant-application'],
        queryFn: () => getApplicantApplications(),
      });

      return data;
    } catch (e: any) {
      throw new Error(e?.message);
    }
  };

  const validateRequestData = (data: LoginRequest) => {
    const { orgId, propertyId, username, password } = data;
    if (!orgId) throw new Error('Org. id is missing.');
    if (!propertyId) throw new Error('Property Id is missing.');
    if (!username) throw new Error('Username is missing.');
    if (!password) throw new Error('Password is missing.');
  };

  const getLatestApplicantResponse = async (responseId: string) => {
    try {
      const { data } = await queryClient.fetchQuery({
        queryKey: ['applicant-response', responseId],
        queryFn: () => getResponse(responseId),
      });
      return data;
    } catch (e: any) {
      throw new Error(e.message);
    }
  };

  const loginRequest = async (data: LoginRequest) => {
    try {
      const response = await mutateAsync(data);
      return response;
    } catch (err: any) {
      throw new Error(err.message);
    }
  };

  const loadLatestResponse = async (responseIds: string[]) => {
    if (!responseIds?.length) return undefined;
    try {
      const response = await getLatestApplicantResponse(responseIds[0]);
      const formValue = prepareFormInitializingData(response);
      dispatch(updateResponseState(formValue));
      return response?.details;
    } catch (err: any) {
      throw new Error(err.message);
    }
  };

  const isApplicationCompleted = (application: ApplicationStatusResponse) => {
    return (
      application.applicantCompletedOn === null ||
      application.applicantCompletedOn === undefined ||
      application.applicantCompletedOn === ''
    );
  };

  const isApplicationExpired = (application: ApplicationStatusResponse) => {
    return application.applicationStatus === EntrataApplicationStatus.Expired;
  };

  const isApplicationIsActive = (applications: ApplicationStatusResponse[]) => {
    const application = applications[0];

    if (!isApplicationCompleted(application)) return false;
    if (isApplicationExpired(application)) return false;
    return true;
  };

  const isPageIdAvailable = (pageId: string, form: Form) => {
    if (!pageId) return false;
    const isAvailable = form.pages.findIndex((page) => page.id === pageId);
    return isAvailable >= 0;
  };

  const getPageIdForRedirection = (response: string, form: Form) => {
    const parseResponse = JSON.parse(response);
    const { pages } = parseResponse;
    const activePage = pages.find((page: any) => page.isCurrentPage);
    if (isPageIdAvailable(activePage?.pageId, form)) return activePage.pageId;

    return form.pages[0].id;
  };

  const getRedirectionRoute = (
    applicantApplication: ApplicationStatusResponse[],
    userResponse: string,
    form: Form,
  ) => {
    if (applicantApplication.length > 1) {
      return { route: 'entry', initialization: false };
    }
    if (!isApplicationIsActive(applicantApplication)) {
      return { route: 'entry', initialization: false };
    }
    const pageId = getPageIdForRedirection(userResponse, form);

    return { route: `${pageId}`, initialization: true };
  };

  const initializeAppAndRedirect = async (
    applicantApplication: ApplicationStatusResponse[],
    userResponse: string,
    form: Form,
    applicant: Applicant,
    orgId: number,
  ) => {
    const { route, initialization } = getRedirectionRoute(
      applicantApplication,
      userResponse as string,
      form,
    );
    dispatch(setApplicationData({ applicant, form }));
    const { responseId } = applicant.responses[0];
    if (initialization) {
      await initializeLJApplication({
        applicant,
        form,
        responseId,
      });
    } else {
      dispatch(setIsPreviewMode(false));
      dispatch(setIsAuthenticated(true));
    }
    navigate(`/${orgId}/${applicant.propertyId}/${route}`);
  };

  const loginUser = async (data: LoginRequest) => {
    setIsLoading(true);
    try {
      validateRequestData(data);
      EntrataSession.setSessionExpired(false);
      const response = await loginRequest(data);
      if (response.status >= 200 && response.status < 300) {
        const { applicant, form } = response.data;
        EntrataSession.setApplicant(applicant);

        const { authorizedResponseIds } = applicant;
        const userResponse = await loadLatestResponse(
          authorizedResponseIds as string[],
        );

        const applicantApplication = await getApplicationsApplication();
        await initializeAppAndRedirect(
          applicantApplication,
          userResponse as string,
          form,
          applicant,
          data.orgId,
        );
      } else {
        throw new Error('Error while login');
      }
    } catch (err: any) {
      throw new Error(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, loginUser };
};

export default useLoginUser;
