"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.STUDENT_FIELDS = void 0;
var entities_1 = require("@common/entities");
exports.STUDENT_FIELDS = [
    {
        label: 'Are you a student at an institute of higher learning?',
        type: entities_1.FieldType.YesNo,
        customType: entities_1.FieldType.YesNo,
        sequence: 1,
        name: 'is_student_higher_learning',
        required: false,
        enabled: true,
    },
    {
        label: 'Student Status',
        type: entities_1.FieldType.Radio,
        customType: entities_1.FieldType.Radio,
        sequence: 2,
        name: 'higher_learning_status',
        required: false,
        enabled: true,
        options: [
            {
                name: 'higher_learning_full_time',
                sequence: 1,
                label: 'Full Time',
            },
            {
                name: 'higher_learning_part_time',
                sequence: 2,
                label: 'Part Time',
            },
        ],
    },
];
