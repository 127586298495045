/* istanbul ignore file */
import { useDispatch } from 'react-redux';
import { queryClient } from 'AppWrapper';
import {
  resetUnitSelection,
  updateUnitSelection,
} from 'state/slices/unitSelectionSlice';

import { getAvailableUnitsAndLeaseTerm, getFloorPlans } from 'services';
import type { FloorPlan, LeaseTerm, Unit } from 'types/floorplan-page-types';
import { getTodaysDate } from 'utils/date';

import { useCheckIsUnitReserved } from './useCheckIsUnitReserved';

export function useSetFloorPlanPageDataInStore() {
  const checkIsUnitReserved = useCheckIsUnitReserved();

  const dispatch = useDispatch();

  async function isUnitReserved(unitSpaceId: number, propertyId: number) {
    const isAvailable = await checkIsUnitReserved(unitSpaceId, propertyId);
    return isAvailable;
  }

  async function fetchAvailableUnitsAndLeaseTerm(
    propertyId: number,
    floorPlanId: number,
    moveInDate: string,
    unitSpaceId: number,
    termMonth: string,
  ) {
    try {
      const data = await queryClient.fetchQuery({
        queryKey: ['units', floorPlanId, moveInDate],
        queryFn: () =>
          getAvailableUnitsAndLeaseTerm(propertyId, floorPlanId, moveInDate),
      });

      const selectedUnit = data.find(
        (i: Unit) => i.unitSpaceId === unitSpaceId,
      );
      if (!selectedUnit) throw new Error('Unit is not available.');

      const selectedLeaseTerm = selectedUnit.leaseTerms.find(
        (i: LeaseTerm) => i.termMonth === termMonth,
      );
      if (!selectedLeaseTerm) throw new Error('Lease term not found.');

      return { unit: selectedUnit, leaseTerm: selectedLeaseTerm };
    } catch {
      throw new Error('Error while fetching available unit');
    }
  }

  async function fetchFloorPlans(
    propertyId: number,
    floorPlanId: number,
    moveInDate: string,
  ) {
    try {
      const data = await queryClient.fetchQuery({
        queryKey: ['floor-plans', propertyId, moveInDate],
        queryFn: () => getFloorPlans(propertyId, moveInDate),
      });

      return data.find((i: FloorPlan) => i.id === floorPlanId);
    } catch {
      throw new Error('Error while fetching floor plans.');
    }
  }

  async function initializeFloorPlanPageData(
    moveInDate: string,
    floorPlanId: number,
    unitSpaceId: number,
    termMonth: string,
    propertyId: number,
    checkReserveUnit = true,
  ) {
    // TODO: Temp code
    const todaysDate = getTodaysDate();

    try {
      if (!floorPlanId || !moveInDate) throw new Error('Missing parameter.');
      const floorPlan = await fetchFloorPlans(
        propertyId,
        floorPlanId,
        todaysDate,
      );

      if (checkReserveUnit) {
        const isReserved = await isUnitReserved(unitSpaceId, propertyId);
        if (!isReserved) {
          dispatch(updateUnitSelection({ floorPlan, moveInDate: todaysDate }));
          throw new Error('Unit is not available.');
        }
      }

      const { unit, leaseTerm } = await fetchAvailableUnitsAndLeaseTerm(
        propertyId,
        floorPlanId,
        todaysDate,
        unitSpaceId,
        termMonth,
      );

      dispatch(
        updateUnitSelection({
          floorPlan,
          unit,
          leaseTerm,
          moveInDate: todaysDate,
        }),
      );
      return 0; // Returning error count for floor plan page
    } catch {
      dispatch(resetUnitSelection());
      return 1; // Returning error count for floor plan page
    }
  }

  return initializeFloorPlanPageData;
}
