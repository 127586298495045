"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultApplicant = exports.Applicant = void 0;
var enums_1 = require("./enums");
var organization_1 = require("./organization");
var Applicant = /** @class */ (function () {
    function Applicant() {
        this.email = '';
        this.org = new organization_1.Organization();
        this.propertyId = 0;
        this.customerId = 0;
        this.id = 0;
        this.sid = '';
        this.applicationId = '';
        this.versionId = '';
        this.context = enums_1.ApplicantContext.FormViewer;
        this.authorizedResponseIds = [];
        this.responses = [];
        this.entrataApplicationId = 0;
        this.leaseId = 0;
    }
    return Applicant;
}());
exports.Applicant = Applicant;
exports.defaultApplicant = {
    email: '',
    org: organization_1.defaultOrganization,
    propertyId: 0,
    responseId: '',
    applicationId: '',
    versionId: '',
    id: 0,
    entrataApplicationId: 0,
    leaseId: 0,
    customerId: 0,
    context: enums_1.ApplicantContext.FormViewer,
    sid: '',
    relationshipType: enums_1.RelationshipType.Primary,
    responses: [],
    authorizedResponseIds: [],
};
