import { useSelector } from 'react-redux';
import { PageType } from '@common/entities';
import { queryClient } from 'AppWrapper';
import { RootState } from 'state/store';

import { getAvailableUnitsAndLeaseTerm, getResponse } from 'services';
import { Unit } from 'types/floorplan-page-types';
import { getTodaysDate } from 'utils/date';
import { prepareFormInitializingData } from 'utils/form';

const useGetSelectedUnitDetails = () => {
  const { form, applicant } = useSelector(
    (state: RootState) => state.application,
  );

  const isFloorPlanPageAvailable = () => {
    const { pages } = form;
    const floorPlanPage = pages.find(
      (page) => page.type === PageType.FloorPlans,
    );

    if (floorPlanPage) return true;
    return false;
  };

  const getSelectedFloorPlanDetails = async (responseId: string) => {
    const { data } = await queryClient.fetchQuery({
      queryKey: ['applicant-response', responseId],
      queryFn: () => getResponse(responseId),
    });
    const formValue: any = prepareFormInitializingData(data);

    if (!formValue?.floorPlanData) return null;

    return formValue.floorPlanData;
  };

  const getUnit = async (value: any) => {
    const { floorplanId, unitSpaceId } = value;

    if (!unitSpaceId) throw new Error('No unit selected was found.');
    // TODO: Temp code
    const todaysDate = getTodaysDate();

    const data = await queryClient.fetchQuery({
      queryKey: ['units', floorplanId, todaysDate],
      queryFn: () =>
        getAvailableUnitsAndLeaseTerm(
          applicant.propertyId,
          floorplanId,
          todaysDate,
        ),
    });

    const selectedUnit = data.find((i: Unit) => i.unitSpaceId === unitSpaceId);
    if (!selectedUnit) throw new Error('Unit is not available.');

    return selectedUnit;
  };

  const getUnitDetail = async (responseId: string) => {
    try {
      const isPageAvailable = isFloorPlanPageAvailable();
      if (!isPageAvailable) throw new Error('Page is not available.');

      const floorPlanDetails = await getSelectedFloorPlanDetails(responseId);
      if (!floorPlanDetails) throw new Error('No unit selected was found.');

      const unit = await getUnit(floorPlanDetails);

      return unit;
    } catch (e: any) {
      throw new Error(e.message);
    }
  };

  return getUnitDetail;
};

export default useGetSelectedUnitDetails;
