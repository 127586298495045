import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {   
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel, 
  Box, 
  Button,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import { PageType, Special, SpecialGroup } from '@common/entities';
import styled from '@emotion/styled';
import { updateSelectedSpecials} from 'state/slices/unitSelectionSlice';
import { RootState } from 'state/store';

import { CrossIcon, PencilIcon } from 'components/custom-icons';
import { ConfirmationModal, OkModal, SpecialsModal } from 'components/modal';
import { useSaveResponse } from 'hooks/useSaveResponse';
import { getPageIndex } from 'utils/page-utils';
import { getAllPromoCodeSpecialsForUnit, getAllStandardSpecialsForUnit } from 'utils/specials';
import useRefreshScheduledCharges from 'utils/useRefreshScheduledCharges';

export function SpecialsCard() {
  const { 
    floorPlan, 
    unit, 
    propertySpecials, 
    floorplanSpecials, 
    unitSpaceSpecials, 
    unitTypeSpecials,
    selectedSpecials,
    promoCodeSpecials,
  } = useSelector(
    (state: RootState) => state.unitSelection,
  );
  const dispatch = useDispatch();
  const { isOpen: isShowTCOpen, onOpen: onShowTCOpen, onClose: onShowTCClose } = useDisclosure();
  const { isOpen: isSpecialsModalOpen, onOpen: onSpecialsModalOpen, onClose: onSpecialsModalClose } = useDisclosure();
  const { isOpen: isRemoveSpecialGroupOpen, onOpen: onRemoveSpecialGroupOpen, onClose: onRemoveSpecialGroupClose } = useDisclosure();
  const [termsAndConditions, setTermsAndConditions] = useState<string>("");
  const [specialGroupToEdit, setSpecialGroupToEdit] = useState<any>(null);
  const [selectedSpecialsInGroupToEdit, setselectedSpecialsInGroupToEdit] = useState<any>(null);
  const [specialGroupRemoveConfirmationMessage, setspecialGroupRemoveConfirmationMessage] = useState<string>("");
  const [promoCode, setPromoCode] = useState<string>("");
  const [promoCodeStatusColor, setPromoCodeStatusColor] = useState<string>("");
  const [promoCodeErrorVisibility, setPromoCodeErrorVisibility] = useState<string>("hidden");
  const allStandardSpecialsForUnit = getAllStandardSpecialsForUnit(
    floorPlan, unit, propertySpecials, floorplanSpecials, unitSpaceSpecials, unitTypeSpecials);
  const allPromoCodeSpecialsForUnit = getAllPromoCodeSpecialsForUnit(floorPlan, unit, promoCodeSpecials);
  const allAvailableSpecialsForUnit = allPromoCodeSpecialsForUnit.concat(allStandardSpecialsForUnit);
  const { propertyId, pages, applicationId, leaseId } = useSelector(
    (state: RootState) => {
      const { form, applicant } = state.application;
      return {
        pages: form.pages,
        propertyId: applicant.propertyId,
        applicationId: applicant.currentResponse.applicationId,
        leaseId: applicant.currentResponse.leaseId,
      };
    },
  );
  const saveResponse = useSaveResponse();
  const refreshScheduledCharges = useRefreshScheduledCharges({
    propertyId,
    leaseId,
  });

  function showTermsAndConditions(terms: string) {
    setTermsAndConditions(terms);
    onShowTCOpen();
  }

  function showSpecialsModal(specialGroup: SpecialGroup, selectedSpecialsInGroup: Special[]) {
    setSpecialGroupToEdit(specialGroup);
    setselectedSpecialsInGroupToEdit(selectedSpecialsInGroup);
    onSpecialsModalOpen();
  }

  function openConfirmationModalForRemovingSpecials(specialGroup: SpecialGroup) {
    setSpecialGroupToEdit(specialGroup);
    if (specialGroup.couponCode) {
      setspecialGroupRemoveConfirmationMessage(`Are you sure you want to remove the special promotion 
        ${specialGroup.couponCode} - ${specialGroup.groupName}?`);
    }
    else {
      setspecialGroupRemoveConfirmationMessage(`Are you sure you want to remove the special promotion 
        ${specialGroup.groupName}? You can reselect this special on the Add-Ons page.`);
    }
    onRemoveSpecialGroupOpen();
  }

  async function handleSpecialGroupRemove() {
    const newSelectedSpecials = selectedSpecials.filter((special: Special) => special.groupId !== specialGroupToEdit.groupId);
    dispatch(updateSelectedSpecials(newSelectedSpecials));
    await updateSpecialsInApplicantResponse(newSelectedSpecials);
    await refreshScheduledCharges();
    
    onRemoveSpecialGroupClose();
  }

  const applyPromoCode = () => {
    const promoCodeSpecial = allPromoCodeSpecialsForUnit.find((special) => special.couponCode === promoCode);
    if (!promoCodeSpecial) {
      setPromoCodeErrorVisibility("visible");
      setPromoCodeStatusColor("red");
      return;
    }
    showSpecialsModal(promoCodeSpecial, []);
  };

  const handlePromoCodeChange = (event: any) => {
    setPromoCode(event.target.value);
    setPromoCodeErrorVisibility("hidden");
    setPromoCodeStatusColor("");
  };

  async function handleUpdateSpecials(updatedSpecials: Special[], specialGroup: SpecialGroup) {
    // Remove all specials in this group from the overall selected specials
    let newSelectedSpecials = selectedSpecials.filter((special: Special) => special.groupId !== specialGroup.groupId); 
    // Add the newly selected group specials back to the overall selected specials
    newSelectedSpecials = newSelectedSpecials.concat(updatedSpecials);
    // Save the new list of overall selected specials
    dispatch(updateSelectedSpecials(newSelectedSpecials));
    await updateSpecialsInApplicantResponse(newSelectedSpecials);
    await refreshScheduledCharges();
    setPromoCode("");

    onSpecialsModalClose();
  }  

  async function updateSpecialsInApplicantResponse(specials: any[])
  {
    const addOnPageIndex = getPageIndex(pages, PageType.Options);
    try {
      const applicantResponse = {
        currentPageId: pages[addOnPageIndex]?.id,
        applicationId,
        propertyId,
        responses: [
          { selectedSpecials: specials,}
        ],
        errorCount: 0,
      };
      await saveResponse(applicantResponse);
    } catch (err: any) {
      throw new Error(err);
    }
  }

  return (
    unit && selectedSpecials.length > 0 && (
      <>
        <Accordion defaultIndex={[0, 1, 2]} allowMultiple>
          <AccItems>
            <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Special Promotions
              </Box>
              <AccIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>           
            <FormLabel id='specialsPromoCodeLabel' color={promoCodeStatusColor}>Promo Code</FormLabel>
            <Grid templateColumns="repeat(3, 1fr)" gap="2">
              <GridItem colSpan={2}>
                <Input color={promoCodeStatusColor} borderColor={promoCodeStatusColor} value={promoCode}
                  id="specialsPromoCodeInput" data-testid="specialsPromoCodeInput" onChange={handlePromoCodeChange}
                />
              </GridItem>
              <Button variant="brand" fontSize={11} height="46px" onClick={applyPromoCode}>
                Apply Code
              </Button>
            </Grid>
            <Text id='specialsPromoCodeErrorText' visibility={promoCodeErrorVisibility as any} 
              fontSize='2xs' fontStyle='italic' color='red'>
                The promo code you entered is invalid. Please try again.</Text>
            { allAvailableSpecialsForUnit.map((specialGroup) => {
              const selectedSpecialsInGroup = selectedSpecials.filter((special) => special.groupId === specialGroup.groupId);
              if (selectedSpecialsInGroup.length === 0) 
                return null;
              const isIncluded = Number(specialGroup.specialLimit) === Number(specialGroup.specialCount);
              const specialGroupHeading = specialGroup.couponCode ? `${specialGroup.couponCode} - ${specialGroup.groupName}` : specialGroup.groupName;
              return (            
                <Box mb={5} mt={5}>
                  <Grid        
                    gap={2}
                    p={2}
                    mt={3}
                    border="1px solid"
                    borderColor="silver.highlight"
                    borderRadius="16px"
                    boxShadow="0px 1px 3px rgba(0, 0, 0, 0.15)"
                  >
                    <Text  whiteSpace="pre-line" fontWeight="bold" fontSize="xs">
                      {specialGroupHeading}
                    </Text>
                    {selectedSpecialsInGroup.map((special: any) => (
                      <Text whiteSpace="pre-line" fontSize="xs">
                        {special.specialName}
                      </Text>
                    ))}
                    <Grid templateColumns="repeat(8, 1fr)" gap="2">
                      <GridItem colSpan={6}>
                        {(specialGroup.termsAndConditions) && (
                          <Text
                            fontSize={10}
                            display={{ base: 'inline', md: 'block' }}
                            color="blue.dusty"
                            cursor="pointer"
                            data-testid={`terms-and-condtions-card-${specialGroup.groupId}`}
                            onClick={(e) => {
                              e.preventDefault();
                              showTermsAndConditions(specialGroup.termsAndConditions);
                            }}
                          >
                            Terms & Conditions
                          </Text>
                        )}  
                      </GridItem>
                      {(!isIncluded) && (
                        <>
                        <GridItem>
                          <Button
                            size="xs"
                            width={5}
                            p={1}
                            bg="silver.light"
                            color="black.base"
                            fontWeight="semibold"
                            onClick={(e) => {
                              e.preventDefault();
                              showSpecialsModal(specialGroup, selectedSpecialsInGroup);
                            }}
                          >
                            <PencilIcon />
                          </Button>
                        </GridItem>
                        <GridItem>
                          <Button
                            size="xs"
                            width={5}
                            p={1}
                            bg="silver.light"
                            color="black.base"
                            fontWeight="semibold"
                            onClick={() => openConfirmationModalForRemovingSpecials(specialGroup)}
                          >
                            <CrossIcon />
                          </Button>
                        </GridItem>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              );
            })}
          </AccordionPanel>
          </AccItems>
        </Accordion>
        <OkModal
          isOpen={isShowTCOpen}
          onClose={onShowTCClose}
          modalHeader='Terms & Conditions'
          modalText={termsAndConditions}
        />
        <SpecialsModal
          modalTitle="Special Promotions"
          specialGroup={specialGroupToEdit}
          isOpen={isSpecialsModalOpen}
          onClose={onSpecialsModalClose}
          selectedSpecials={selectedSpecialsInGroupToEdit ?? []}
          handleSave={handleUpdateSpecials}
        />   
        <ConfirmationModal
          isOpen={isRemoveSpecialGroupOpen}
          onClose={onRemoveSpecialGroupClose}
          onClickAction={handleSpecialGroupRemove}
          modalText={specialGroupRemoveConfirmationMessage}
          actionBtnVariant="danger"
          actionBtnText="Delete"
        />     
      </>
  )
);
}

const AccItems = styled(AccordionItem)`
  border-top: 1px solid ${({ theme }: any) => theme.colors.gray.light};
`;
const AccIcon = styled(AccordionIcon)`
  background: ${({ theme }: any) => theme.colors.gray.light};
  border-radius: 4px;
  width: 25px;
  height: 25px;
`;
