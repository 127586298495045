"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Feature = void 0;
var enums_1 = require("./enums");
var Feature = /** @class */ (function () {
    function Feature() {
        this.name = enums_1.FeatureFlag.None;
        this.enabled = false;
    }
    return Feature;
}());
exports.Feature = Feature;
