import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowBackIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  HStack,
  Image,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { applicationBrandingResponseSelector } from 'state/selectors';

import { LogoutIcon } from 'components/custom-icons';
import { Loader } from 'components/Loader';
import { useLogout } from 'hooks/auth';

import MobileMenu from '../../menu/MobileMenu';

export default function LJHeader() {
  const [isLargerThan] = useMediaQuery('(min-width: 62em)');
  const {
    value: responseState,
    form,
    media,
  } = useSelector(applicationBrandingResponseSelector);
  const { name } = form;

  const navigate = useNavigate();
  const { orgId, propertyId, pageId } = useParams();

  const { url = '', slot_alt: alt = '' } = media.landing_logo ?? {};
  const redirectLink = sessionStorage.getItem('entrataRedirectLink');

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const prevTimestampRef = useRef(performance.now());

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const currentTimestamp = performance.now();
      const timeDifference = currentTimestamp - prevTimestampRef.current;

      const scrollDifference = prevScrollPos - currentScrollPos;
      const scrollSpeed = Math.abs(scrollDifference) / timeDifference;

      const speedThreshold = 0.25;

      if (currentScrollPos < 10) {
        setVisible(true);
      } else if (
        prevScrollPos > currentScrollPos &&
        scrollSpeed > speedThreshold
      ) {
        setVisible(true);
      } else if (prevScrollPos < currentScrollPos) {
        setVisible(false);
      }

      setPrevScrollPos(currentScrollPos);
      prevTimestampRef.current = currentTimestamp;
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);

  const handleRedirectClick = () => {
    if (redirectLink) {
      window.location.href = redirectLink;
    }
  };
  const { handleLogout, isLoading } = useLogout();

  if (isLoading) return <Loader label="Logging out..." />;

  if (!isLargerThan) {
    return (
      <Box
        as="nav"
        w="100%"
        position="fixed"
        top="0"
        zIndex="1"
        display="flex"
        justifyContent="space-between"
        minH="72px"
        background="white.base"
        boxShadow={visible ? '0px 2px 2px 0px rgb(0 0 0 / 10%)' : 'none'}
        transform={visible ? 'none' : 'translateY(-100%)'}
        transition="transform 0.3s ease-in-out"
      >
        <MobileMenu />
      </Box>
    );
  }

  return (
    <Box
      as="nav"
      w="100%"
      position="fixed"
      top="0"
      zIndex="1"
      display="flex"
      justifyContent="space-between"
      padding="16px 24px"
      minH="72px"
      background="white.base"
      boxShadow="0px 2px 2px 0px rgb(0 0 0 / 10%)"
    >
      {!pageId ? (
        <HStack spacing={4}>
          {media?.landing_logo ? (
            <Box onClick={handleRedirectClick} cursor="pointer">
              <Image src={url} maxH="64px" alt={alt} />
            </Box>
          ) : (
            <Text
              fontSize="lg"
              fontWeight="bold"
              onClick={handleRedirectClick}
              cursor="pointer"
            >
              {name}
            </Text>
          )}
        </HStack>
      ) : (
        <Button
          bg="transparent"
          _hover={{ bg: 'transparent' }}
          size="md"
          onClick={() => navigate(`/${orgId}/${propertyId}/entry`)}
        >
          <ArrowBackIcon fontSize="20px" />
        </Button>
      )}
      <HStack spacing={4}>
        <Text fontSize="md">
          {responseState?.applicant_name_first}{' '}
          {responseState?.applicant_name_last}
        </Text>
        <Button
          size="md"
          leftIcon={<LogoutIcon />}
          variant="link"
          onClick={handleLogout}
        >
          Logout
        </Button>
      </HStack>
    </Box>
  );
}
