"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GUARANTOR_FIELDS = void 0;
exports.GUARANTOR_FIELDS = [
    {
        label: 'Previous Address Section',
        sequence: 1,
        name: 'previous_address_section',
        required: false,
        enabled: false,
    },
    {
        label: 'Personal Reference #1',
        sequence: 2,
        name: 'personal_reference_1',
        required: false,
        enabled: false,
    },
    {
        label: 'Personal Reference #2',
        sequence: 3,
        name: 'personal_reference_2',
        required: false,
        enabled: false,
    },
];
