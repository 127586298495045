import { ProspectDocument } from '@common/entities';
import { get, post } from 'config/api/config';

const POLICY_DOCUMENT_ENDPOINT = '/viewer/policy-documents';
const PROSPECT_DOCUMENT_ENDPOINT = '/viewer/prospect-documents';

export const getPolicyDocument = async (fileAssociationId: number) => {
  const response = await get(
    `${POLICY_DOCUMENT_ENDPOINT}/${fileAssociationId}`,
    {
      responseType: 'arraybuffer',
    },
  );
  return response;
};

export const getPolicyDocumentForSecondaryApplicant = async (
  fileAssociationId: number,
  email: string,
) => {
  const response = await get(
    `${POLICY_DOCUMENT_ENDPOINT}/secondary/${fileAssociationId}/${email}`,
    {
      responseType: 'arraybuffer',
    },
  );
  return response;
};

export const getPolicyDocuments = async () => {
  const response = await get(POLICY_DOCUMENT_ENDPOINT);
  return response;
};

export const getPolicyDocumentsForSecondaryApplicant = async (
  email: string,
) => {
  const response = await get(`${POLICY_DOCUMENT_ENDPOINT}/secondary/${email}`);
  return response;
};

export const signPolicyDocument = async (
  fileAssociationId: number,
  initials: string,
) => {
  const response = await get(
    `${POLICY_DOCUMENT_ENDPOINT}/sign/${fileAssociationId}/${initials}`,
  );
  return response;
};

export const signPolicyDocumentForSecondaryApplicant = async (
  fileAssociationId: number,
  initials: string,
  email: string,
) => {
  const response = await get(
    `${POLICY_DOCUMENT_ENDPOINT}/sign/secondary/${fileAssociationId}/${initials}/${email}`,
  );
  return response;
};

export const getProspectDocuments = async () => {
  const response = await get<ProspectDocument[]>(PROSPECT_DOCUMENT_ENDPOINT);
  return response?.data;
};

export const getProspectDocument = async (documentId: number) => {
  if (!documentId) throw new Error('Document Id is missing.');

  const response = await get(`${PROSPECT_DOCUMENT_ENDPOINT}/${documentId}`, {
    responseType: 'arraybuffer',
  });
  return response;
};

export const signProspectDocument = async (
  documentId: number,
  fullName: string,
) => {
  const response = await post(
    `${PROSPECT_DOCUMENT_ENDPOINT}/signDocument/${documentId}`,
    {
      documentId,
      fullName,
    },
  );
  return response;
};
