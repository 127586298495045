"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.COAPPLICANT_FIELDS = void 0;
var entities_1 = require("@common/entities");
exports.COAPPLICANT_FIELDS = [
    {
        label: 'Relationship to Applicant',
        type: entities_1.FieldType.Dropdown,
        sequence: 1,
        name: 'relationship',
        customType: entities_1.FieldType.Dropdown,
        required: false,
        enabled: true,
    },
    {
        label: 'Show date of birth for occupants under 18 years of age',
        type: entities_1.FieldType.Date,
        sequence: 2,
        name: 'date_of_birth',
        customType: entities_1.FieldType.Date,
        required: false,
        enabled: true,
    },
    {
        label: 'Allow Primary to fill out form for co-applicants',
        type: entities_1.FieldType.Radio,
        sequence: 3,
        name: 'allow_primary_enter_coapplicant_info',
        customType: entities_1.FieldType.Radio,
        required: false,
        enabled: true,
    },
];
