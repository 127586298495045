"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Tabs = {
    baseStyle: {
        tab: {
            fontWeight: 'bold',
            _selected: {
                color: 'blue.700',
            },
        },
    },
    variants: {
        enclosed: {
            tab: {
                _selected: {
                    bgColor: 'white',
                    color: 'black',
                },
                border: 'none',
                bgColor: 'gray.100',
                borderTopLeftRadius: 'xl',
                borderTopRightRadius: 'xl',
                _hover: {
                    bgColor: 'gray.200',
                },
            },
            tablist: {
                borderBottom: 'none',
            },
        },
        underlined: {
            tab: {
                fontWeight: 'semibold',
                borderBottom: '4px solid transparent',
                _selected: {
                    borderBottom: '4px solid #3182CE',
                    color: 'blue.700',
                },
                _hover: {
                    color: 'blue.700',
                },
                pb: 2,
            },
        },
    },
};
exports.default = Tabs;
