"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DISABILITY_FIELDS = void 0;
var entities_1 = require("@common/entities");
exports.DISABILITY_FIELDS = [
    {
        label: 'Are you disabled?',
        type: entities_1.FieldType.YesNo,
        customType: entities_1.FieldType.YesNo,
        sequence: 1,
        name: 'is_disabled',
        required: false,
        enabled: true,
    },
    {
        label: 'Disability Type',
        type: entities_1.FieldType.ChooseManyDropdown,
        customType: entities_1.FieldType.ChooseManyDropdown,
        sequence: 2,
        name: 'disability_type',
        required: false,
        enabled: true,
        options: [
            {
                name: 'disability_mobility',
                sequence: 1,
                label: 'Mobility',
            },
            {
                name: 'disability_hearing',
                sequence: 2,
                label: 'Hearing',
            },
            {
                name: 'disability_visual',
                sequence: 3,
                label: 'Visual',
            },
            {
                name: 'disability_mental',
                sequence: 4,
                label: 'Mental',
            },
        ],
    },
];
