import { get, post } from 'config/api/config';

import { MoneyGramAccountInfo, PaymentType } from 'types/payments';

const PAYMENTS_ENDPOINTS = '/payments';

export const getPaymentConfirmationDetails = async () => {
  const response = await post(PAYMENTS_ENDPOINTS);
  return response;
};

export const getPaymentTypes = async (amount: number, responseId: string) => {
  if (amount === null || amount === undefined) {
    throw new Error('Please provide valid amount.');
  }
  const response = await post<PaymentType[]>('viewer/payment-types', {
    paymentAmount: amount,
    responseId,
  });
  return response;
};

interface PlaidLinkTokenResponse {
  environment: string;
  expiration: string;
  linkToken: string;
  status: boolean;
  url: string;
}
export const getPlaidLinkToken = async (responseId: string) => {
  const response = await post<PlaidLinkTokenResponse>(
    'viewer/plaid/link-token',
    {
      callbackURL: window.location.href,
      responseId,
    },
  );
  return response;
};

interface CardRequest {
  nameOnCard: string;
  secureToken: string;
  maskedCardNumber: string;
  expMonth: number;
  expYear: number;
  cvv: number;
  postalCode: number;
  nickname: string;
  typeId: number;
  isCreditCard: boolean;
  isDebitCard: boolean;
}

interface ACHRequest {
  linkSessionId: string;
  accountIds: string[];
  institutionId: string | null;
  institutionName: string | null;
  publicToken: string;
  verificationStatus: string;
}

export type PaymentScreening = {
  applicant_name_first: string;
  applicant_name_middle: string;
  dont_have_middle_name: boolean;
  applicant_name_last: string;
  applicant_birth_date: string;
  country: string;
  street_line1: string;
  street_line2: string;
  city: string;
  state_code: string;
  zip_code: string;
  is_self_verified: boolean;
};

interface AddPaymentRequest {
  paymentTypeId: number;
  cardInfo?: CardRequest;
  bank_info?: ACHRequest;
  isScreeningEnabled?: boolean;
  paymentScreening?: PaymentScreening;
  responseId: string;
}
export const addPaymentAccount = async (data: AddPaymentRequest) => {
  const response = await post('viewer/payment-account', data);

  return response;
};

interface MakePaymentRequest {
  paymentAmount: number;
  paymentAccountId: number;
  paymentTypeId: number;
  responseId: string;
}
interface MakePaymentResponse {
  success: boolean;
  totalAmount: number;
  payments: {
    id: number;
    result: string;
    amount: number;
    fee: number;
    total: number;
    status: string;
    type: string;
    createdCustomerName: string;
  }[];
}
export const makePayment = async (data: MakePaymentRequest) => {
  const response = await post<MakePaymentResponse>('viewer/payments', data);

  return response;
};

export const getPaymentSettings = async () => {
  const response = await get('viewer/payment-settings');
  return response;
};

export const getPaymentAccounts = async () => {
  const response = await get('viewer/payment-accounts');
  return response;
};

export const getMoneyGramAccountInfo = async () => {
  const response = await get<MoneyGramAccountInfo>(
    'viewer/moneygram/account-info',
  );
  return response?.data;
};
