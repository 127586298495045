"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntrataPermission = void 0;
var enums_1 = require("./enums");
var EntrataPermission = /** @class */ (function () {
    function EntrataPermission() {
        this.name = enums_1.EntrataPermissionName.None;
        this.isAllowed = false;
    }
    return EntrataPermission;
}());
exports.EntrataPermission = EntrataPermission;
