import { useDispatch, useSelector } from 'react-redux';
import { PaymentAccount as PaymentAccountEntity } from '@common/entities';
import { useMutation } from '@tanstack/react-query';
import { PaymentAccount, setPaymentAccount } from 'state/slices/paymentSlice';
import { RootState } from 'state/store';

import { useGetCardChargesDue } from 'hooks/payments';
import {
  addPaymentAccount,
  getPaymentAccounts,
  makePayment,
  PaymentScreening,
} from 'services';
import { cardKeyMapping, prepareCardRequest } from 'utils/payment/card-utils';

export function useProcessCardPayment() {
  const dispatch = useDispatch();

  const { paymentInfo, paymentSettings } = useSelector(
    (state: RootState) => state.payment,
  );
  const { charges } = useSelector((state: RootState) => state.shoppingCart);
  const { responseId } = useSelector(
    (state: RootState) => state.application.activeResponse,
  );

  const { isPending: isAddPaymentLoading, mutateAsync: addPaymentCall } =
    useMutation({
      mutationFn: addPaymentAccount,
    });
  const { isPending: isPaymentProcessing, mutateAsync: makePaymentRequest } =
    useMutation({
      mutationFn: makePayment,
    });
  const {
    isPending: isGettingPaymentAccounts,
    mutateAsync: getPaymentAccountsCall,
  } = useMutation({
    mutationFn: getPaymentAccounts,
  });

  const getCardCharges = useGetCardChargesDue();

  function getDueNowCharges() {
    if (!charges.length) return 0;
    return charges
      .filter((charge) => charge.dueNow)
      .reduce((acc, { priceNumber }) => {
        return acc + priceNumber;
      }, 0);
  }

  async function completePayment(paymentAccount: any) {
    const { id: paymentAccountId, paymentTypeId } = paymentAccount;

    const dueNowCharges = getDueNowCharges();
    const paymentTypeCharges = getCardCharges();

    try {
      await makePaymentRequest({
        paymentAmount: dueNowCharges + paymentTypeCharges,
        paymentAccountId,
        paymentTypeId,
        responseId,
      });
    } catch (err: any) {
      throw new Error(err?.message ?? 'Something went wrong.');
    }
  }

  function getPaymentAccount(
    cardData: any,
    postalCode: string,
    paymentAccounts: PaymentAccountEntity[],
  ): PaymentAccountEntity | null {
    const { maskedCardNumber, expMonth, expYear, nameOnCard } =
      cardData.cardInfo;
    const account = paymentAccounts.find(
      (a: PaymentAccountEntity) =>
        a.paymentTypeId === cardData.paymentTypeId &&
        a.name === nameOnCard &&
        a.mask?.slice(-4) === maskedCardNumber?.slice(-4) &&
        a.expMonth === expMonth &&
        Number(a.expYear?.toString().substring(2)) === expYear &&
        a.postalCode === postalCode,
    );
    return account ?? null;
  }

  async function processCardPayment(ofacFormValue?: PaymentScreening) {
    const {
      card_number: cardNumber,
      name_on_card: nameOnCard,
      expiry_date: expDate,
      postal_code: postalCode,
      cvv,
    } = paymentInfo;

    const mappedData = cardKeyMapping({
      ...cardNumber,
      name_on_card: nameOnCard,
      postal_code: postalCode,
      cvv,
    });

    try {
      const request: any = prepareCardRequest(
        mappedData,
        expDate,
        paymentSettings,
        ofacFormValue,
      );
      request.responseId = responseId;
      const accountsResponse = await getPaymentAccountsCall();

      let paymentAccount: PaymentAccount;
      const paymentAccountEntity = getPaymentAccount(
        request,
        postalCode,
        accountsResponse.data,
      );
      if (paymentAccountEntity) {
        paymentAccount = {
          id: paymentAccountEntity.id,
          paymentTypeId: request.paymentTypeId,
          paymentTypeName: '',
          mask: request.cardInfo.maskedCardNumber,
          type: request.cardInfo.typeId,
          expMonth: request.cardInfo.expMonth,
          expYear: request.cardInfo.expYear,
          name: request.cardInfo.nameOnCard,
        };
      } else {
        const response = await addPaymentCall(request);
        paymentAccount = {
          id: response.data.id,
          paymentTypeId: response.data.paymentTypeId,
          paymentTypeName: '',
          mask: response.data.mask,
          type: response.data.type,
          expMonth: response.data.expMonth,
          expYear: response.data.expYear,
          name: response.data.name,
        };
      }

      dispatch(setPaymentAccount(paymentAccount));
      await completePayment(paymentAccount);
    } catch (err: any) {
      throw new Error(err?.message ?? 'Something went wrong.');
    }
  }

  return {
    isPaymentProcessing,
    processCardPayment,
    isAddingPayment: isAddPaymentLoading,
    isGettingPaymentAccounts,
  };
}
