"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Heading = {
    baseStyle: {
        fontWeight: '700',
        marginBottom: '0',
    },
    variants: {
        PageTitle: {
            fontSize: '18px',
            lineHeight: '22px',
            letterSpacing: '0.5px',
            marginBottom: 4,
        },
        SectionHeading: {
            fontSize: '18px',
            lineHeight: '22px',
            letterSpacing: '0.5px',
        },
        BlockHeading: {
            fontSize: '15px',
            fontWeight: '500',
            lineHeight: '18px',
            letterSpacing: '0.25px',
        },
    },
    sizes: {
        '2xl': {
            fontSize: '2rem',
        },
        xl: {
            fontSize: '1.5rem',
        },
        lg: {
            fontSize: '1.325rem',
        },
        md: {
            fontSize: '1rem',
        },
        sm: {
            fontSize: '0.9rem',
        },
    },
};
exports.default = Heading;
