import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

import actionsReducer from './slices/actionsSlice';
import applicationScreeningReducer from './slices/applicationScreeningSlice';
import applicationReducer from './slices/applicationSlice';
import authReducer from './slices/authSlice';
import brandingReducer from './slices/brandingSlice';
import fieldSettingsReducer from './slices/fieldSettingsSlice';
import leadProfileReducer from './slices/leadProfileSlice';
import navigationReducer from './slices/navigationSlice';
import paymentReducer from './slices/paymentSlice';
import petsReducer from './slices/petsSlice';
import propertySettingsReducer from './slices/propertySettingsSlice';
import responseReducer from './slices/responseSlice';
import shoppingCartReducer from './slices/shoppingCartSlice';
import unitSelectionReducer from './slices/unitSelectionSlice';

export const rootReducer = combineReducers({
  auth: authReducer,
  response: responseReducer,
  branding: brandingReducer,
  fieldSettings: fieldSettingsReducer,
  leadProfile: leadProfileReducer,
  application: applicationReducer,
  navigation: navigationReducer,
  payment: paymentReducer,
  shoppingCart: shoppingCartReducer,
  unitSelection: unitSelectionReducer,
  pets: petsReducer,
  applicationScreening: applicationScreeningReducer,
  actions: actionsReducer,
  propertySettings: propertySettingsReducer,
});

export const persistConfig = {
  key: 'root',
  storage: storageSession,
  whitelist: ['auth', 'application', 'branding'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'],
      },
    }),
  devTools: process.env.NODE_ENV === 'development',
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
