import { useDispatch, useSelector } from 'react-redux';
import { PageType } from '@common/entities';
import { setLoadingState } from 'state/slices/actionsSlice';
import { updateLeasingJourneyErrors } from 'state/slices/navigationSlice';
import { RootState } from 'state/store';

import { useSetFloorPlanPageDataInStore } from 'hooks/floor-plans';
import { useSaveResponse } from 'hooks/useSaveResponse';
import { Quote } from 'types/page';
import { getPageIndex } from 'utils/page-utils';

interface Props {
  activeStepIndex: number;
}
const useSaveQuote = ({ activeStepIndex }: Props) => {
  const dispatch = useDispatch();
  const { quote, quoteLeaseTerm } = useSelector(
    (state: RootState) => state.unitSelection,
  );
  const { propertyId, pages, applicationId } = useSelector(
    (state: RootState) => {
      const { form, applicant } = state.application;
      return {
        pages: form.pages,
        propertyId: applicant.propertyId,
        applicationId: applicant.applicationId,
      };
    },
  );

  const initializeFloorPlan = useSetFloorPlanPageDataInStore();
  const saveResponse = useSaveResponse();

  const getFloorPlanInitializationValue = (data: Quote, term: number) => {
    const {
      moveInDate,
      leaseTerms,
      unitDetails: { id, floorId },
    } = data;

    const leaseTerm = leaseTerms.find((i) => i.id === term);
    if (!leaseTerm) throw new Error('Lease term not found.');

    return {
      moveInDate,
      propertyId,
      floorPlanId: floorId,
      unitSpaceId: id,
      termMonth: leaseTerm.name,
    };
  };

  const validateFloorPlanData = (
    moveInDate: string,
    floorPlanId: number,
    propId: number,
    unitSpaceId: number,
    termMonth: string,
  ) => {
    if (!moveInDate) throw new Error('Move-in date not found.');
    if (!floorPlanId) throw new Error('Floor plan Id not found.');
    if (!propId) throw new Error('Property Id not found.');
    if (!unitSpaceId) throw new Error('Unit space Id not found.');
    if (!termMonth) throw new Error('Lease term not found.');
  };

  const initializeFloorPlanPage = async (
    data: Quote,
    term: number,
    floorPlanPageIndex: number,
  ) => {
    if (floorPlanPageIndex < 0) return;
    try {
      const {
        moveInDate,
        floorPlanId,
        propertyId: propId,
        unitSpaceId,
        termMonth,
      } = getFloorPlanInitializationValue(data, term);
      validateFloorPlanData(
        moveInDate,
        floorPlanId as number,
        propId,
        unitSpaceId,
        termMonth,
      );
      const errorCount = await initializeFloorPlan(
        moveInDate,
        floorPlanId as number,
        unitSpaceId,
        termMonth,
        propId,
        false,
      );

      dispatch(
        updateLeasingJourneyErrors({
          pageIndex: floorPlanPageIndex,
          count: errorCount,
        }),
      );
    } catch (e: any) {
      throw new Error(e.message);
    }
  };

  const saveQuotes = async (targetIndex: number) => {
    try {
      const { id: quoteId } = quote as Quote;
      await saveResponse({
        currentPageId: pages[activeStepIndex]?.id,
        applicationId,
        propertyId,
        responses: [
          {
            quote: {
              quoteId,
              leaseTermId: quoteLeaseTerm,
            },
          },
        ],
        targetPageId: pages[targetIndex]?.id,
        errorCount: 0,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const updateFloorPlanResponse = async (floorPlanPageIndex: number) => {
    if (floorPlanPageIndex < 0) return;
    try {
      await saveResponse({
        currentPageId: pages[floorPlanPageIndex]?.id,
        applicationId,
        propertyId,
        responses: [],
        errorCount: 0,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const handleQuotesSelection = async (targetPageIndex: number) => {
    dispatch(setLoadingState({ isLoading: true, message: 'Saving Quotes...' }));
    try {
      if (!quote) throw new Error('Please select quote.');
      if (!quoteLeaseTerm) throw new Error('Quote lease term not found.');

      const floorPlanPageIndex = getPageIndex(pages, PageType.FloorPlans);

      await initializeFloorPlanPage(quote, quoteLeaseTerm, floorPlanPageIndex);
      await saveQuotes(targetPageIndex);
      await updateFloorPlanResponse(floorPlanPageIndex);
    } catch {
      /* empty */
    } finally {
      dispatch(
        updateLeasingJourneyErrors({
          pageIndex: activeStepIndex,
          count: 0,
        }),
      );
      dispatch(setLoadingState({ isLoading: false, message: '' }));
    }
  };

  return handleQuotesSelection;
};

export default useSaveQuote;
