import * as React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import ProtectedRoute from 'auth/ProtectedRoute';
import PageNotFound from 'pages/PageNotFound';
import SelectNewApplicationPage from 'pages/SelectNewApplicationPage';
import StatusPage from 'pages/StatusPage';
import { RootState } from 'state/store';

import PdfSigner from 'components/documents/PdfSigner';
import PdfViewer from 'components/documents/PdfViewer';
import { Loader } from 'components/Loader';

const RedirectToForm = React.lazy(() => import('pages/RedirectToForm'));
const LeasingJourney = React.lazy(() => import('pages/LeasingJourney'));
const LeadProfile = React.lazy(() => import('pages/lead-profile/LeadProfile'));
const EntryPage = React.lazy(() => import('pages/EntryPage'));
const ThankYouPage = React.lazy(() => import('pages/ThankYouPage'));
const AuthenticationScreen = React.lazy(
  () => import('auth/AuthenticationScreen'),
);
const PreviewApplication = React.lazy(
  () => import('pages/preview-application/PreviewApplication'),
);
const EntrataProspectLogin = React.lazy(
  () => import('auth/EntrataProspectLogin'),
);
const ScreeningConditionsPage = React.lazy(
  () =>
    import(
      'components/render-page/pages/screening-conditions/ScreeningConditionsPage'
    ),
);
const ScreeningConditionPayment = React.lazy(
  () =>
    import(
      'components/render-page/pages/screening-conditions/ScreeningConditionPayment'
    ),
);
const ScreeningConditionConfirmation = React.lazy(
  () =>
    import(
      'components/render-page/pages/screening-conditions/ScreeningConditionConfirmation'
    ),
);
const ProspectResetPassword = React.lazy(
  () => import('pages/ResetPasswordForm'),
);

function App() {
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  return (
    <React.Suspense fallback={<Loader />}>
      <Routes>
        <Route path="preview/:versionId" element={<PreviewApplication />} />
        <Route
          path="/lead-profile/verify-session/:orgId/:propertyId/entrata-lead-profile-login"
          element={<LeadProfile />}
        />
        <Route
          path="/:orgId/:propertyId/reset-password"
          element={<ProspectResetPassword />}
        />
        {isAuthenticated ? (
          <>
            <Route
              path="/:orgId/:propertyId/:pageId"
              element={<LeasingJourney />}
            />
            <Route
              path="/:orgId/:propertyId/screening"
              element={<ScreeningConditionsPage />}
            />
            <Route
              path="/:orgId/:propertyId/new-application"
              element={<SelectNewApplicationPage />}
            />
            <Route
              path="/:orgId/:propertyId/screening/payment"
              element={<ScreeningConditionPayment />}
            />
            <Route
              path="/:orgId/:propertyId/screening/confirmation"
              element={<ScreeningConditionConfirmation />}
            />
            <Route
              path="/:orgId/:propertyId/"
              element={<Navigate to="entry" />}
            />
            <Route path="/:orgId/:propertyId/entry" element={<EntryPage />} />
            <Route path="/:orgId/:propertyId/viewPdf/:pageId" element={<PdfViewer />} />
            <Route path="/:orgId/:propertyId/signPdf/:pageId" element={<PdfSigner />} />
            <Route
              path="/:orgId/:propertyId/submission-confirmation"
              element={<ThankYouPage />}
            />
            <Route
              element={
                <ProtectedRoute>
                  <Outlet />
                </ProtectedRoute>
              }
            >
              <Route element={<RedirectToForm />} />
            </Route>
          </>
        ) : (
          <>
            <Route
              path="/:orgId/:propertyId"
              element={<AuthenticationScreen />}
            />
            <Route
              path="/:orgId/:propertyId/:pageId"
              element={<AuthenticationScreen />}
            />
            <Route
              path="/:orgId/:propertyId/entrata-prospect-login"
              element={<EntrataProspectLogin />}
            />
            <Route path="/status/:status" element={<StatusPage />} />
          </>
        )}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </React.Suspense>
  );
}

export default App;
