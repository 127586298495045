import { post } from 'config/api/config';

import { Applicant } from 'types/applicant';
import { Form } from 'types/form';

const LOGIN_ENDPOINT = '/viewer/login';
const LOGOUT_ENDPOINT = '/viewer/logout';
const CREATE_ACCOUNT_ENDPOINT = '/viewer/signup';
const ENTRATA_PROSPECT_LOGIN_ENDPOINT = '/viewer/entrata/prospect-login';
const LEAD_PROFILE_VERIFY_SESSION_ENDPOINT = '/lead-profile/verify-session';

interface LoginProps {
  orgId: number;
  propertyId: number;
  username: string;
  password: string;
}
interface AuthResponse {
  applicant: Applicant;
  form: Form;
}

interface CreateUserAccountProps {
  orgId: number;
  applicationId: string;
  propertyId: string | number;
  previewFormVersionId: string;
  relationshipType: string;
  applicant: {
    firstName: string;
    lastName: string;
    birthDate: string;
    email: string;
    password: string;
  };
}

interface EntrataProspectLoginProps {
  orgId: number;
  accessToken: string | null;
}

interface EntrataProspectLoginResponse {
  applicant: Applicant;
  form: Form;
}
interface LeadProfileVerifySessionProps {
  orgId: number;
  propertyId: number;
  token: string;
  userId: number;
}

/**
 * Log user in
 * @returns promise
 */
export const postUserLogin = async ({
  orgId,
  propertyId,
  username,
  password,
}: LoginProps) => {
  if (!orgId) throw new Error('Client Id is missing.');
  if (!propertyId) throw new Error('Property Id is missing.');
  if (!username) throw new Error('Email is missing.');
  if (!password) throw new Error('Password is missing.');

  const response = await post<AuthResponse>(LOGIN_ENDPOINT, {
    orgId,
    propertyId,
    username,
    password,
  });
  return response;
};

interface LogoutResponse {
  message: string;
}
export const postUserLogout = async () => {
  const response = await post<LogoutResponse>(LOGOUT_ENDPOINT);
  return response;
};

export const postCreateUserAccount = async (
  createAccountData: CreateUserAccountProps,
) => {
  const response = await post<AuthResponse>(
    CREATE_ACCOUNT_ENDPOINT,
    createAccountData,
  );
  return response;
};

/**
 * Entrata user log in
 * @returns promise
 */
export const postEntrataProspectLogin = async (
  loginData: EntrataProspectLoginProps,
) => {
  const { orgId, accessToken } = loginData;

  if (!orgId || !accessToken) throw new Error('Missing parameter.');

  const response = await post<EntrataProspectLoginResponse>(
    ENTRATA_PROSPECT_LOGIN_ENDPOINT,
    loginData,
  );
  return response?.data;
};

/**
 * Entrata user log in
 * @returns promise
 */
export const postLeadProfileVerifySession = async (
  leadProfileVerifySessionData: LeadProfileVerifySessionProps,
): Promise<any> => {
  const response = await post(
    LEAD_PROFILE_VERIFY_SESSION_ENDPOINT,
    leadProfileVerifySessionData,
  );
  return response;
};
