"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CITIZEN_STATUS_FIELDS = void 0;
var entities_1 = require("@common/entities");
var constants_1 = require("../../constants");
exports.CITIZEN_STATUS_FIELDS = [
    {
        name: 'applicant_subsidy_citizenship_type_id',
        type: entities_1.FieldType.Dropdown,
        label: 'What is your citizenship status?',
        enabled: true,
        required: true,
        isConfigurable: false,
        sequence: 1,
        customType: entities_1.FieldType.Dropdown,
    },
    {
        name: 'applicant_has_ssn',
        type: entities_1.FieldType.YesNo,
        customType: entities_1.FieldType.YesNo,
        label: 'Do you have a Social Security Number (SSN)?',
        options: constants_1.YES_NO_OPTIONS,
        enabled: true,
        isConfigurable: false,
        sequence: 2,
        required: true,
    },
    {
        name: 'applicant_ssn_number',
        type: entities_1.FieldType.SSN,
        label: 'Enter your SSN',
        customType: entities_1.FieldType.SSN,
        enabled: true,
        required: true,
        isConfigurable: false,
        sequence: 3,
    },
    {
        name: 'applicant_ssn_exception',
        type: entities_1.FieldType.Radio,
        customType: entities_1.FieldType.Radio,
        label: 'SSN Exception',
        options: constants_1.SSN_EXCEPTION_OPTIONS,
        enabled: true,
        required: true,
        sequence: 4,
    },
    {
        name: 'applicant_customer_subsidy_details_tracs_id',
        type: entities_1.FieldType.Text,
        customType: entities_1.FieldType.Text,
        label: 'Enter TRACS ID',
        enabled: true,
        required: true,
        sequence: 5,
    },
];
